@font-face {
  font-family: 'Spectrum Sans';
  src: url('/public/fonts/SpectrumSans.woff2') format('woff2');
}
body {
  margin: 0;
  font-family: 'Spectrum Sans, Helvetica, Arial, sans-serif !important';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8F8F8;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
